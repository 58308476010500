// Copyright (C) 2018 Pepperdata Inc. - All rights reserved.
// @flow

import { SyntheticEvent } from "react";

const BASE_URL = window.location.origin;
import { isDemo, isLDAPEnabled } from "../utils";
import { DEFAULT_ERROR_MSG } from "../constants";

export const signInWrapper = (emailAddress: string, password: string) => {
  if (isLDAPEnabled()) {
    return fetch("/api/auth/ldaplogin", {
      method: "post",
      body: JSON.stringify({
        username: emailAddress,
        password,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error, status = " + response.status);
        }
        if (response.redirected) {
          window.location.href = response.url;
        } else {
          window.location.href = "/";
        }
      })
      .catch(() => {
        throw "invalid username or password";
      });
  } else if (isDemo()) {
    return fetch("/api/auth/demologin", {
      method: "post",
      body: JSON.stringify({ username: emailAddress }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error, status = " + response.status);
        }
        window.location.href = "/";
      })
      .catch(() => {
        throw "unable to log user in with email";
      });
  } else {
    return fetch("/api/auth/okta/authn", {
      method: "post",
      body: JSON.stringify({ userName: emailAddress, password: password }),
    }).then((response) => {
      if (response.redirected && response.status === 200) {
        // success, redirect to dashboard page
        window.location = response.url;
      } else {
        // handle error
        let errorMessage = "Error logging in. Please try again.";
        return response
          .json()
          .then((jsonError) => {
            if (jsonError.error) {
              errorMessage = jsonError.error;
            }
            throw new Error(errorMessage);
          })
          .catch(() => {
            throw new Error(errorMessage);
          });
      }
    });
  }
};

// Handle error responses from window.fetch by manually throwing an error.
// Reason is because window.fetch will only reject a promise if the endpoint
// is offline. Even when the response has status 500 it will not throw an error.
// More info: https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
export const handleErrors = (response: Response): Promise<any> => {
  if (!response.ok) {
    // Default error message if all other properties do not exist.
    let errorMessage = DEFAULT_ERROR_MSG;

    // Get error message from status text.
    if (response.statusText) {
      errorMessage = response.statusText;
    }

    // Check to see if response is coming from Tomcat server. If so, the error message
    // lives within the HTML error report. Return the report as the error message.
    return response.text().then(
      (errorReportText) => {
        if (errorReportText) {
          throw Error(errorReportText);
        }

        // If report does not exist, throw error with message we retrieved earlier.
        throw Error(errorMessage);
      },
      () => {
        // If a problem occured while reading the body as text,
        // we throw error with message we retrieved earlier.
        throw Error(errorMessage);
      }
    );
  } else {
    // No errors occured, moving on by returning original response.
    return Promise.resolve(response);
  }
};

export const requestResetPassword = (emailAddress: string) => {
  // Request to reset password for the given email address
  const url = BASE_URL + "/api/auth/reset-request";
  const paramsObj = { email: emailAddress };

  return window
    .fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: convertToSearchParams(paramsObj),
    })
    .then(handleErrors);
};

// Helper function to convert objects to strings to pass in the body of a POST
// Convert an object from { email: 'abc@email.com', name: 'kevin' }
// to string 'email=abc%40email.com&name=kevin'
export const convertToSearchParams = (paramsObj: { [any]: string }) => {
  return Object.keys(paramsObj)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(paramsObj[key]);
    })
    .join("&");
};

export const resetPassword = (password: string, passwordToken: string) => {
  const url = BASE_URL + "/api/auth/reset-password";
  const paramsObj = { password, token: passwordToken };

  return window
    .fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: convertToSearchParams(paramsObj),
    })
    .then(handleErrors);
};

export const fetchPasswordRequirements = (
  emailAddress: string
): Promise<any> => {
  const url =
    BASE_URL +
    "/password-requirements?email=" +
    encodeURIComponent(emailAddress);

  return window
    .fetch(url, {
      method: "GET",
    })
    .then(handleErrors)
    .then((response) => response.json());
};

export const fetchPasswordRequirementsFromToken = (
  resetToken: string
): Promise<any> => {
  const url =
    BASE_URL +
    "/password-requirements?reset-token=" +
    encodeURIComponent(resetToken);

  return window
    .fetch(url, {
      method: "GET",
    })
    .then(handleErrors)
    .then((response) => response.json());
};

export const createAccount = (
  email: string,
  password: string,
  firstName: string,
  lastName: string
) => {
  const url = BASE_URL + "/api/auth/user";
  const paramsObj = { email, password, firstName, lastName };

  return window
    .fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: convertToSearchParams(paramsObj),
    })
    .then(handleErrors);
};

export const ssoLogin = (email: string) => {
  const url =
    BASE_URL + "/api/auth/samlredirect?email=" + encodeURIComponent(email);
  window.location.href = url;
};
